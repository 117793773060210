import React, { Fragment } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Grid } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { CardItem, Description, SubDescription } from "../components";
import bg from "../assets/images/background.svg";
import mainLogo from "../assets/images/main_logo.svg";
import daslMedia from "../assets/images/dasl_media.svg";
import heybuddyMedia from "../assets/images/heybuddy_media.svg";

const MainBox = styled(Box)({
  backgroundColor: "rgb(0, 0, 0, 0.7)",
  width: "100%",
  height: "100vh",
  padding: "0 50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const RedirectionBox = styled(Box)({
  backgroundColor: "#1E1E2E",
  width: "100%",
  height: "100vh",
  padding: "0 50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const MainContainer = styled(Container)({
  maxWidth: "100vw !important",
  height: "100vh",
  padding: "0 !important",
  backgroundImage: `url(${bg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
});

const Home = () => {
  return (
    <Fragment>
      <CssBaseline />
      <MainContainer>
        <Grid container>
          <Grid item xs={7} sm={6} md={7}>
            <MainBox>
              <Box
                component="img"
                src={mainLogo}
                sx={{
                  width: 335,
                  height: 90,
                  maxWidth: { md: 335 },
                  maxHeight: { md: 90 },
                }}
              />
              <Description />
              <SubDescription />
            </MainBox>
          </Grid>
          <Grid item xs={5} sm={6} md={5}>
            <RedirectionBox>
              <Box
                component="div"
                sx={{
                  maxWidth: 625,
                  minWidth: 385.5,
                  textAlign: "center",
                  fontWeight: 300,
                  fontSize: "26px",
                  lineHeight: "31.2px",
                  marginBottom: "32px",
                  color: "white",
                }}
              >
                This platform is currently being built and is a work in progress. All functionalities are not yet working.
              </Box>
              <CardItem
                title="DASL"
                subTitle="IUP"
                description="Incident management module"
                mediaSrc={daslMedia}
                directTo="#"
                btnName="Login to IUP"
              />
              <CardItem
                title="HeyBuddy"
                description="Tenants management module"
                mediaSrc={heybuddyMedia}
                directTo="#"
                btnName="Login to HeyBuddy"
              />
            </RedirectionBox>
          </Grid>
        </Grid>
      </MainContainer>
    </Fragment>
  );
}

export { Home };
export default Home;
