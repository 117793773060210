import React from "react";
import { Card, Button, CardMedia, Typography, CardContent, Box } from "@mui/material";

const CardItem = ({title, description, mediaSrc, directTo, btnName, subTitle = ""}) => (
  <Card 
    sx={{
      minWidth: 385.5,
      maxWidth: 625,
      width: "100%",
      display: "flex",
      backgroundColor: "#1E1E2E",
      borderRadius: "8px",
      marginBottom: "32px",
    }}
  >
    <CardMedia
      component="img"
      sx={{ width: { sm: 150, md: 180 }, height: "100%" }}
      image={mediaSrc}
      alt={`${title} logo`}
    />
    <CardContent
      sx={{
        padding: "21px 32px",
        backgroundColor: "#27293C",
        width: "100%",
      }}>
      <Typography
        component="div"
        sx={{
          fontFamily: `'Lato', sans-serif`,
          fontWeight: 700,
          fontSize: "32px",
          lineHeight: "38.4px",
          marginBottom: "8px",
          color: "white",
          width: "100%",
        }}
      >
        {title}
        {subTitle && <Box component="span" sx={{ fontWeight: 300 }}>&nbsp;{subTitle}</Box>}
      </Typography>
      <Typography
        component="div"
        sx={{
          fontFamily: `'Lato', sans-serif`,
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "19.2px",
          marginBottom: {sm: "16px", md: "32px"},
          color: "white",
        }}
      >
        {description}
      </Typography>
      <Button
        variant="contained"
        href={directTo}
        sx={{
          width: "100%",
          height: 46,
          backgroundColor: "#4D7DF2",
          fontFamily: `'Lato', sans-serif`,
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19.2px",
          textAlign: "center",
        }}
      >
        {btnName}
      </Button>
    </CardContent>
  </Card>
)

export default CardItem;
export { CardItem };