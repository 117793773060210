import React from "react";
import { Box } from "@mui/material";
import daslMark from "../assets/images/dasl_mark.svg";
import isemsMark from "../assets/images/isems_mark.svg";
import vector from "../assets/images/vector.svg";

const SubDescription = () => (
  <Box
    component="div"
    sx={{
      maxWidth: 435,
      minWidth: 345.5,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <Box component="div" sx={{ width: 125, maxWidth: 125 }}>
      <Box
        component="div"
        sx={{
          width: 125,
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "16.8px",
          color: "white",
          paddingLeft: "2px",
        }}
      >
        INITIATED BY
      </Box>
      <Box
        component="img"
        src={daslMark}
        sx={{
          width: 125,
          height: 26,
          maxWidth: { md: 125 },
          maxHeight: { md: 26 },
          marginBottom: "15.6px",
        }}
        alt="DASL IUP logo"
      />
    </Box>
    <Box component="div" sx={{ width: 174.53, maxWidth: 174.53 }}>
      <Box
        component="div"
        sx={{
          width: 174.53,
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "16.8px",
          color: "white",
          paddingLeft: "2px",
        }}
      >
        IN PARTNERSHIP WITH
      </Box>
      <Box
        component="img"
        src={isemsMark}
        sx={{
          width: 121,
          height: 26,
          maxWidth: { md: 121 },
          maxHeight: { md: 26 },
          marginTop: "18px",
          marginBottom: "18px",
        }}
        alt="isems mark logo"
      />
      <Box
        component="img"
        src={vector}
        alt="vector logo"
      />
    </Box>
  </Box>
)

export default SubDescription;
export { SubDescription };