import React from "react";
import logo from "../assets/images/DASL_logo.svg";
import { Box } from "@mui/material";

const Description = () => (
  <Box component="div" sx={{ textAlign: "center", margin: "64px 0 64px 0" }}>
    <Box
      component="img"
      src={logo}
      sx={{
        width: 218.56,
        height: 36.41,
        marginBottom: "15.6px",
      }}
      alt="DASL IUP logo"
    />
    <Box
      component="div"
      sx={{
        maxWidth: 446,
        minWidth: 345.5,
        width: "100%",
        fontWeight: 300,
        fontSize: "26px",
        lineHeight: "31.2px",
        textAlign: "center",
        color: "white",
      }}
    >
      DASL Intelligent Unified Platform (IUP) , the ultimate tool to digitally transform the dormitory industry in Singapore
    </Box>
  </Box>
)

export default Description;
export { Description };